import * as React from "react";

// markup
const NotFoundPage = () => {
  return (
    <React.Fragment>
      <h1>Page not found 404</h1>
    </React.Fragment>
  );
};

export default NotFoundPage;
